// JavaScript Document
$('.scrolltop').hide();
$(document).ready(function() {
	
	$(window).scroll(function(){
    if ($(this).scrollTop() > 500) {
      $('.scrolltop').fadeIn();
    } else {
      $('.scrolltop').fadeOut();
    }
  });
	
});